import React from "react"
import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@components/Button/Button"

import { graphql } from "gatsby"
import ResponsiveImage from "../components/ResponsiveImage"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import GetStarted from "../components/CTA/GetStarted"

function createHtml(html) {
  return { __html: html }
}
const showdown = require("showdown")

const converter = new showdown.Converter()

class iCatPage extends React.Component {
  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]
    return (
      <SharedStateProvider>
        <Layout pageTitle="i-cat-page">
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            pathname={this.props.location.pathname}
          />

          <div className="procedure-body three-d-body">
            <section className="body-sections section">
              <div className="columns">
                <div className="column is-5" />
                <div
                  className="column"
                  dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.body)
                  )}
                />
                <div className="column is-5" />
              </div>
            </section>
          </div>

          <GetStarted
            className="get-started-cta three-d-cta color-back"
            centerHeading
            centerText
            headingSideColumnIs={5}
            sideColumnIs={4}
            heading={post.getStarted.heading}
            paragraph={post.getStarted.blurb}
            // buttonText={post.getStarted.buttonText}
            // buttonUrl={post.getStarted.href}
            buttons={post.getStarted.buttons}
          />
        </Layout>
      </SharedStateProvider>
    )
  }
}

export const pageQuery = graphql`
  query threeDImagingPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        blurb
        metaTitle
        metaDescription
        youtube
        imageId
        imageIdMobile
        body
        getStarted {
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
          blurb
          buttonText
          heading
          href
        }
      }
    }
  }
`

export default iCatPage
